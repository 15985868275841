<template>
  <div id="user-profile-verify">
    <b-btn
      v-if="!expired"
      aria-label="back"
      variant="link"
      class="back-button"
      @click="clickBack"
    >
      <i class="material-icons">arrow_back</i>
    </b-btn>
    <h1 class="profile-title">
      {{ title }}
    </h1>
    <p class="profile-description">
      {{ description }}
    </p>
    <h1
      v-if="type === 'email' || type === 'email-expired'"
      class="profile-title"
    >
      {{ email }}
    </h1>
    <h1
      v-if="type === 'phone' || type === 'phone-expired'"
      class="profile-body-title"
    >
      <FormSection>
        <FormGroup
          id="verification-code"
          :state="state"
          :class="[{'invalid': state}]"
          :invalid-feedback="invalidFeedback"
          for="auth"
          role="alert"
        >
          <b-input
            id="verification-code-field"
            v-model="auth"
            type="tel"
            maxlength="4"
          />
        </FormGroup>
      </FormSection>
    </h1>
    <b-btn
      v-if="type !== 'email'"
      :disabled="disabled"
      class="profile-button w-100"
      variant="link"
      @click="clickUpdate"
    >
      {{ buttonText }}
    </b-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';

export default {
  name: 'UserProfileVerify',
  components: {
    FormSection,
    FormGroup,
  },
  data() {
    return {
      type: this.$route.params.type,
      expired: (this.$route.params.type === 'email-expired' || this.$route.params.type === 'phone-expired'),
      disabled: false,
      auth: '',
      invalidFeedback: '',
      state: null,
      timer: 0,
    };
  },
  computed: {
    ...mapGetters([
      'email',
      'phone',
    ]),
    title() {
      switch (this.type) {
        case 'email':
          return 'Check your email';
        case 'phone':
          return `Enter the 4-digit code texted to you at [${this.phone}]`;
        case 'email-expired':
        case 'phone-expired':
          return 'Your link has expired';
        default:
          return '';
      }
    },
    description() {
      switch (this.type) {
        case 'email':
          return 'We just sent an email to the address below. Tap the link in the email to verify your address.';
        case 'phone':
          return '';
        case 'email-expired':
          return 'Tap the \'Resend Email\' button below and we\'ll send a new link to your email address.';
        case 'phone-expired':
        default:
          return 'Who are you?';
      }
    },
    buttonText() {
      if (this.disabled && !(this.type === 'phone')) return 'SENT';
      switch (this.type) {
        case 'email':
          return 'RESEND EMAIL';
        case 'phone':
          if (this.timer > 0) {
            return `RESEND CODE IN ${this.timer}`;
          } else {
            return 'RESEND CODE';
          }
        case 'email-expired':
          return 'RESEND EMAIL';
        case 'phone-expired':
        default:
          return 'Shoo..!! Don\'t touch me!';
      }
    },
  },
  watch: {
    auth(val) {
      if (val.length === 4) {
        this.verify();
      }
    },
  },
  mounted() {
    if (this.type === 'phone') this.minuteCountdown();
  },
  methods: {
    clickBack() {
      this.$store.dispatch('getUserProfile');
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/profile`);
    },
    clickUpdate() {
      switch (this.type) {
        case 'phone':
          this.disabled = true;
          this.sendCode();
          break;
        case 'email-expired':
          this.disabled = true;
          this.$store.dispatch('verificationResend', { type: this.$route.query.type, key: this.$route.query.key });
          break;
        case 'email':
        case 'phone-expired':
        default:
          break;
      }
    },
    async sendCode() {
      this.disabled = true;
      try {
        await this.$store.dispatch('userVerify');
        this.state = null;
        this.invalidFeedback = '';
        this.minuteCountdown();
      } catch (error) {
        console.error(error);
        this.state = 'invalid';
        this.invalidFeedback = error.errorMessage;
        this.disabled = false;
      }
    },
    async verify() {
      try {
        await this.$store.dispatch('userVerifyConfirm', this.auth);
        await this.$router.push({ path: '/mobile/profile/confirm', query: { type: 'PHONE_RESET' } });
      } catch (error) {
        console.error(error);
        this.state = 'invalid';
        this.invalidFeedback = `Error: ${error.errorMessage}`;
        this.auth = '';
      }
    },
    minuteCountdown() {
      this.disabled = true;
      this.timer = 60;
      const minuteTimer = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0) {
          this.disabled = false;
          clearInterval(minuteTimer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
$char-w: 1ch;
$gap: .4*$char-w;
$n-char: 4;
$in-w: $n-char*($char-w + $gap);
#user-profile-verify {
  padding: 30px 15px;
  .back-button {
    position: absolute;
    top: 30px;
    left: 15px;
    display: inline;
    color: white;
  }
  .invalid-feedback {
    position: absolute;
  }
  #verification-code {
    width: 180px;
    height: 55px;
    margin: 2em auto;
    border: 1px solid;
    padding: 0px 25px 5px 30px;
    border-radius: 8px;
    &:focus-within {
      border: 1px solid #21BECE;
    }
    .invalid-feedback {
      margin-left: -80px;
      margin-top: 10px;
      width: 280px;
      font-size: 12px;
    }
    #verification-code-field {
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      font-weight: 600;
      font-size: 36px !important;
      line-height: 43px;
      display: block;
      width: $in-w;
      background-color: rgba(255, 255, 255, 0) !important;
      background: repeating-linear-gradient(90deg,
        white 0, white $char-w,
        transparent 0, transparent $char-w + $gap)
        0 100%/ #{$in-w - $gap} 2px no-repeat;
      letter-spacing: $gap;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
</style>
